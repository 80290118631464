import { Box, Flex } from '@chakra-ui/react';
import { debounce, omit } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { GlobalReducerState } from 'app/reducers';
import { setEnrollmentEventType } from 'Containers/App/actions';
import {
  makeSelectConfigField,
  makeSelectGlobalField,
  makeSelectJvpField,
  selectIsFirstPageView,
} from 'Containers/App/selectors';
import { AlexIdUser, AlexProducts, TextField } from 'Containers/App/types';
import { selectHealthPlan } from 'Containers/CommercialRoutes/actions';
import {
  changeFormValue,
  changeSurveyValue,
  createOrUpdateHousehold,
  editDependent,
  editPolicyholder,
  editSpouse,
  getHousehold,
  prefillUserProfile,
  removeDependent,
  removeSpouse,
  resetCountyResults,
  searchDrugs,
  searchLocations,
  submitClientSurvey,
  submitIncentiveSurvey,
} from 'Containers/ProfilePage/actions';
import {
  makeGetHouseholdId,
  makeGetIsDuringOE,
  makeGetSelectedEnrollmentEvent,
  makeGetSelectedProduct,
  makeGetSelectedPublicationKey,
  makeGetSelectedPublicationPlanYear,
  makeGetStateCodeOptions,
  makeGetSurveyQuestions,
  makeSelectClientSurveyIsLoaded,
  makeSelectClientSurveyResponses,
  makeSelectCountyResults,
  makeSelectDependents,
  makeSelectDrugResults,
  makeSelectIsHouseholdLoaded,
  makeSelectPolicyholder,
  makeSelectProfileField,
  makeSelectRxIsLoading,
  makeSelectRxIsSkipped,
  makeSelectShouldShowTobaccoQuestion,
  makeSelectShowClientSurvey,
  makeSelectSpouse,
  makeSelectSurvey,
} from 'Containers/ProfilePage/selectors';
import {
  ClientSurveyQuestion,
  ClientSurveyResponsesByYear,
  CountyResult,
  IncentiveSurveyAnswer,
  IncentiveSurveyQuestion,
  Member,
  MemberSectionConfigFields,
  ProfileSection,
  RxSearchResults,
  Survey,
  UpdateMemberFields,
} from 'Containers/ProfilePage/types';
import { ContentfulInfoBars } from 'ContentfulWrappers/ContentfulInfoBars';
import { ContentfulHeaderWrapper } from 'ContentfulWrappers/header';
import { useAudioContext } from 'Contexts/audioContext';
import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { useProfileContext } from 'Contexts/profileContext';
import { useTextContext } from 'Contexts/textContext';
import { FadeUp } from 'DesignLibrary/atoms';
import { PageLayout } from 'DesignLibrary/atoms/PageLayout';
import { PageLayoutContainer } from 'DesignLibrary/atoms/PageLayout/styled';
import { Spinner } from 'DesignLibrary/atoms/Spinner';
import { useViewport } from 'DesignLibrary/context';
import Audio from 'Shared/Audio/index';
import { RenderMediaControls } from 'Shared/RenderMediaControls';
import { AriaHidden, AriaHiddenAlert } from 'Utils/accessibility';
import { EnrollmentEventType, JVPBootstrapPublicationInfo, JVPEnrollmentEvent } from 'Utils/apiTypes';
import injectSaga from 'Utils/injectSaga';
import { InsightsEventData, sendInsightsEvent } from 'Utils/insights';
import { PROFILE_PATH } from 'Utils/urls';

import CapacityToPaySection from './CapacityToPaySection';
import { ContinueBanner } from './ContinueBanner';
import EligibilitySection from './EligibilitySection';
import EnrollmentSection from './EnrollmentSection';
import { IncentiveSection } from './IncentiveSection';
import IncomeSection, { FilingStatus } from './IncomeSection';
import LocationSection from './LocationSection';
import MemberSection from './MemberSection';
import PregnancySection from './PregnancySection';
import { PrivacyBanner } from './PrivacyBanner';
import { AudioConfigContentfulPaths, getCompletionAudioForSection } from './ProfileAudio';
import { ProgressGraphic } from './ProgressGraphic';
import RiskAssessmentSection from './RiskAssessmentSection';
import saga from './saga';
import {
  LoadingCard,
  ProfileAudioContainer,
  ProfileAudioSubsContainer,
  ProfileContainer,
  RightColumnContainer,
} from './styled';

interface ReduxProps {
  isHouseholdLoaded: boolean;
  questions: Record<string, ClientSurveyQuestion>;
  responses: ClientSurveyResponsesByYear;
  clientSurveyIsLoaded: boolean;
  prefillHasCompleted: boolean;
  countyResults: CountyResult[];
  zipcode: string;
  stateCode: string;
  stateCodeOptions: string[];
  policyholder: Member;
  spouse: Member;
  dependents: Member[];
  drugResults: RxSearchResults;
  isRxLoading: boolean;
  isRxSkipped: boolean;
  survey: Survey;
  collectTobaccoUsageForPolicyholder: boolean;
  collectTobaccoUsageForSpouse: boolean;
  collectTaxInputs: boolean;
  collectIsSpouseOrDomesticPartner: boolean;
  filingStatus: string;
  showClientSurvey: boolean;
  isFirstPageView: boolean;
  householdId: string | null;
  selectedProduct: AlexProducts | '';
  hiddenQuestions: { eligibility: string[]; location: string[] };
  employeeId?: string;
  enrollmentEvent?: JVPEnrollmentEvent;
  user?: AlexIdUser;
  selectedPublicationKey: 'active' | 'upcoming';
  isDuringOE: boolean;
  activePublication: JVPBootstrapPublicationInfo | null;
  upcomingPublication: JVPBootstrapPublicationInfo | null;
  isIntegratedUser: boolean;
  prefillIntegratedProfile: boolean;
  isIncentiveLoading: boolean;
  incentiveSurvey: IncentiveSurveyQuestion[];
  incentiveSurveyAnswers: Record<string, IncentiveSurveyAnswer | null>;
  isReturnUser: boolean | null;
  planYear?: number;
}

export type ProfilePageProps = {
  fetchHousehold: () => void;
  searchCounties: (zipcode: string) => void;
  resetCounties: () => void;
  handleZipChange: (value: string) => void;
  handleStateChange: (value: string) => void;
  submitClientSurveyResponses: (ClientSurveyResponses) => void;
  submitIncentiveSurveyResponses: (incentiveSurveyResonses) => void;
  handleEditPolicyholder: (update: UpdateMemberFields) => void;
  handleEditSpouse: (update: UpdateMemberFields) => void;
  handleEditDependent: (memberId: string, update: UpdateMemberFields) => void;
  handleDeleteSpouse: () => void;
  handleDeleteDependent: (memberId: string) => void;
  handleSavePregnancyAnswer: (value: boolean | '') => void;
  handleSaveFilingStatus: (value: string) => void;
  handleChangeRiskQuestion1: (value: string) => void;
  handleChangeRiskQuestion2: (value: string) => void;
  handleChangeCapacityToPay: (value: string) => void;
  handleSaveEnrollmentAnswer: (value: string) => void;
  handleSetEnrollmentEventType: (value: string | null, publicationId?: string, employeeId?: string) => void;
  queryDrugs: (value: string) => void;
  prefillUserProfile: (memberConfig: MemberSectionConfigFields) => void;
  createUpdateHouseholdAndIncentiveSurvey: () => void;
} & ReduxProps;

export const ProfilePage = ({
  isHouseholdLoaded,
  questions,
  responses,
  clientSurveyIsLoaded,
  prefillHasCompleted,
  countyResults,
  zipcode,
  stateCode,
  stateCodeOptions,
  policyholder,
  spouse,
  dependents,
  survey,
  collectTobaccoUsageForPolicyholder,
  collectTobaccoUsageForSpouse,
  collectTaxInputs,
  collectIsSpouseOrDomesticPartner,
  filingStatus,
  isFirstPageView,
  showClientSurvey,
  drugResults,
  isRxLoading,
  isRxSkipped,
  householdId,
  selectedProduct,
  activePublication,
  upcomingPublication,
  hiddenQuestions,
  user,
  selectedPublicationKey,
  isDuringOE,
  enrollmentEvent,
  employeeId,
  isIntegratedUser,
  prefillIntegratedProfile,
  isIncentiveLoading,
  incentiveSurvey,
  incentiveSurveyAnswers,
  isReturnUser,
  planYear,
  fetchHousehold,
  searchCounties,
  resetCounties,
  handleZipChange,
  handleStateChange,
  submitClientSurveyResponses,
  submitIncentiveSurveyResponses,
  handleEditPolicyholder,
  handleEditSpouse,
  handleEditDependent,
  handleDeleteSpouse,
  handleDeleteDependent,
  handleSavePregnancyAnswer,
  handleSaveFilingStatus,
  handleChangeRiskQuestion1,
  handleChangeRiskQuestion2,
  handleChangeCapacityToPay,
  handleSaveEnrollmentAnswer,
  handleSetEnrollmentEventType,
  queryDrugs,
  prefillUserProfile,
  createUpdateHouseholdAndIncentiveSurvey,
}: ProfilePageProps): JSX.Element => {
  const [hiddenAlert, setHiddenAlert] = useState<string | null>(null);
  const [shouldPrefill, setShouldPrefill] = useState<boolean>(false);

  const isDevMode = localStorage.getItem('dev');

  const { device } = useViewport();

  const { retrieveContentfulData } = useTextContext();

  const {
    completedSections = [],
    shouldUpdateIncentives,
    setShouldUpdateIncentives,
    getIsUnlocked,
    getIsDirty,
    getIsFormValid,
    getIsFormCompleted,
    handleSectionChange,
    handleHiddenEligibility,
    getIsIncentivesValuesValid,
    selectedPublicationInfo,
    profileProgressMap,
  } = useProfileContext();

  const { is_integration_pre_fill_enabled, is_planned_procedures_enabled, is_skip_income_enabled } =
    useFeatureFlagContext();
  const selectedProductIsBc = selectedProduct === 'bc';
  const isProfileAudioEnabled = selectedProductIsBc;
  const isPlannedProceduresEnabled = is_planned_procedures_enabled;
  const isSkipIncomeEnabled = is_skip_income_enabled;

  const [skipSectionCompleteAudio, setSkipSectionCompleteAudio] = useState<boolean>(false);

  const memberConfig: MemberSectionConfigFields = {
    collectTobaccoUsageForPolicyholder,
    collectTobaccoUsageForSpouse,
    collectIsSpouseOrDomesticPartner,
    isProductBc: selectedProductIsBc,
    enablePlannedProcedures: isPlannedProceduresEnabled,
    sex_at_birth_enabled: selectedPublicationInfo?.publication?.sex_at_birth_enabled ?? true,
    allow_sex_at_birth_no_answer_enabled:
      selectedPublicationInfo?.publication?.allow_sex_at_birth_no_answer_enabled ?? false,
  };

  useEffect(() => {
    if (householdId && !isHouseholdLoaded) {
      fetchHousehold();
    }

    if (hasOnlyDefaultedDropdown() || hasOnlyZipQuestion()) {
      handleHiddenEligibility();
    }

    const userLoggedIn = user?.alex_id_uuid;
    const doPrefillWithLockingForIntegratedUsers = userLoggedIn && prefillIntegratedProfile;
    const doPrefill = userLoggedIn && is_integration_pre_fill_enabled;
    setSkipSectionCompleteAudio(!!(doPrefillWithLockingForIntegratedUsers || doPrefill));

    if (doPrefillWithLockingForIntegratedUsers) {
      // Never happens, deprecated functionality
      prefillUserProfile(memberConfig);
      setShouldPrefill(true);
    } else if (doPrefill) {
      // Only happens if user logs in on ULP
      prefillUserProfile(memberConfig);
      setShouldPrefill(true);
    }
  }, []);

  const userLoggedInAndClientSurveyLoaded = clientSurveyIsLoaded && user?.alex_id_uuid;

  useEffect(() => {
    // Prefill Client Survey (once loaded), if applicable
    if (userLoggedInAndClientSurveyLoaded && prefillIntegratedProfile) {
      // Never happens, deprecated functionality
      prefillUserProfile(memberConfig);
      setShouldPrefill(true);
    } else if (userLoggedInAndClientSurveyLoaded && is_integration_pre_fill_enabled) {
      // note here that the is_integration_pre_fill_enabled feature flag controls ALL prefill, not just integrated
      prefillUserProfile(memberConfig);
      setShouldPrefill(true);
    }
  }, [clientSurveyIsLoaded]);

  // Ensure eligibility section remains hidden until prefill is complete, if we are prefilling
  const readyToRenderEligibilitySection = shouldPrefill ? prefillHasCompleted : clientSurveyIsLoaded;

  const getMostRecentlyCompletedSection = () => completedSections[completedSections.length - 1];

  // Fire insights event
  useEffect(() => {
    if (completedSections.length > 0) {
      const mostRecentlyCompleted = getMostRecentlyCompletedSection();
      const userAnswers: Record<ProfileSection, unknown> = {
        enrollment: survey?.enrollment_question,
        eligibility: responses,
        location: zipcode,
        member: {
          policyholder,
          spouse,
          dependents,
        },
        pregnancy: survey.plan_child_question,
        income: policyholder.income,
        riskAssessment: {
          risk_question_1: survey.risk_question_1,
          risk_question_2: survey.risk_question_2,
        },
        capacityToPay: survey.capacity_to_pay,
        incentives: incentiveSurveyAnswers,
      };
      sendInsightsEvent(
        null,
        `profile_${mostRecentlyCompleted}_section_complete`,
        userAnswers as InsightsEventData,
      );
    }
  }, [completedSections]);

  const [sectionAudioPlayed, setSectionAudioPlayed] = useState<string[]>([]);

  const playSectionAudio = (mostRecentlyCompletedSection: ProfileSection) => {
    if (skipSectionCompleteAudio) {
      // Don't play it; we might be prefilling and don't want that
      // to trigger section complete audio, cutting off intro audio
      // In this position, it can still trigger if a section is manually updated after
      return;
    }
    const profileAudio = getCompletionAudioForSection(mostRecentlyCompletedSection);
    if (!profileAudio) {
      // No entry for this section.
      return;
    }

    if (!playAudio(deviceIsDesktop, profileAudio as TextField)) return;

    // Maintain which audio clips have been played because we want to play each
    //   only once.
    sectionAudioPlayed.push(mostRecentlyCompletedSection);
    setSectionAudioPlayed(sectionAudioPlayed);
  };

  // Play audio clip on form load.
  useEffect(() => {
    if (isProfileAudioEnabled) {
      playAudio(deviceIsDesktop, AudioConfigContentfulPaths.StartedProfile as TextField);
    }
  }, []);

  // Play audio clips when sections are completed.
  useEffect(() => {
    if (isProfileAudioEnabled && completedSections.length > 0) {
      const mostRecentlyCompleted = getMostRecentlyCompletedSection();
      if (!sectionAudioPlayed.includes(mostRecentlyCompleted)) {
        playSectionAudio(mostRecentlyCompleted);
      }
    }
  }, [completedSections]);

  // Play audio clip on form completion.
  useEffect(() => {
    if (getIsFormCompleted()) {
      playAudio(deviceIsDesktop, AudioConfigContentfulPaths.CompletedProfile as TextField);
    }
  }, [getIsFormCompleted()]);

  const questionValues: ClientSurveyQuestion[] = Object.entries(questions)
    .filter(([key]) => key !== 'zip5')
    .map(([, val]) => val);

  function hasOnlyZipQuestion() {
    /* Determines whether the zip5 question is the only client survey question.
       If so, we should hide the entire eligibility section as the response is handled in the LocationSection.
    */
    return questions.zip5 && Object.keys(questions).length === 1;
  }

  function hasOnlyDefaultedDropdown() {
    /**
     * Determines whether the only client survey question is a select component with only one option.
     * If so, we should hide the entire eligibility section as SurveyQuestion has logic to automatically
     * add this option to responses
     */
    return questionValues.length === 1 && questionValues[0]?.enum?.length === 1;
  }

  // Display Question Logic
  const displayEligibility =
    showClientSurvey && getIsUnlocked('eligibility') && !hasOnlyZipQuestion() && !hasOnlyDefaultedDropdown();

  const displayLocation =
    !showClientSurvey || getIsUnlocked('location') || hasOnlyZipQuestion() || hasOnlyDefaultedDropdown();

  const displayEnrollment = getIsUnlocked('enrollment');
  const displayMember = getIsUnlocked('member');
  const displayPregnancy = getIsUnlocked('pregnancy');
  const displayIncome = getIsUnlocked('income');
  const displayRisk = getIsUnlocked('riskAssessment');
  const displayCapacity = getIsUnlocked('capacityToPay');

  const areSectionsPriorToIncentivesComplete = Object.values(omit(profileProgressMap, 'incentives')).every(
    (section) => section.isValid || section.isDisabled,
  );
  const displayIncentives =
    getIsUnlocked('incentives') && incentiveSurvey.length > 0 && areSectionsPriorToIncentivesComplete;

  const displayContinue = getIsFormValid();

  useEffect(() => {
    // enrollment is not displayed if there is one plan year and during OE
    //  or if the customer is integrated and has an upcoming publication
    if (
      (!displayEnrollment && isDuringOE && enrollmentEvent?.jv_publication_uuid) ||
      (isIntegratedUser && !!upcomingPublication)
    ) {
      // send open_enrollment as event_type to BC and insights
      handleSetEnrollmentEventType('open_enrollment', enrollmentEvent?.jv_publication_uuid, employeeId);
      sendInsightsEvent(null, 'enrollment_question_event_type', {
        event_type: 'open_enrollment',
      });
    }
  }, [enrollmentEvent?.jv_publication_uuid]);

  const debouncedUpdateHouseholdAndIncentiveSurvey = useCallback(
    debounce(() => {
      createUpdateHouseholdAndIncentiveSurvey();
    }, 1000),
    [],
  );

  // Handle initial /incentives request
  useEffect(() => {
    if (getIsUnlocked('incentives') && !householdId) {
      createUpdateHouseholdAndIncentiveSurvey();
    }
  }, [getIsUnlocked('incentives')]);

  // Handle subsequent update /incentives requests
  useEffect(() => {
    if (shouldUpdateIncentives && householdId) {
      debouncedUpdateHouseholdAndIncentiveSurvey();
    }
    setShouldUpdateIncentives(false);
  }, [shouldUpdateIncentives]);

  // Need to cancel debounce if it's firing with invalid values (empty TextInputs)
  const shouldCancelDebounce = !getIsIncentivesValuesValid();
  useEffect(() => {
    if (shouldCancelDebounce) {
      debouncedUpdateHouseholdAndIncentiveSurvey.cancel();
    }
  }, [shouldCancelDebounce]);

  // If eligibility section is hidden because it only has one question and that question is a single-option dropdown,
  // this block will auto-select that only option.
  if (!displayEligibility && hasOnlyDefaultedDropdown() && !displayLocation) {
    const [key, val] = Object.entries(questions)[0];
    submitClientSurveyResponses({
      ...responses,
      [selectedPublicationKey]: {
        ...responses[selectedPublicationKey],
        [key]: val?.enum?.[0],
      },
    });
  }

  const hiddenInstructions = retrieveContentfulData<string>('profile_section.accessibility.instructions');

  const deviceIsDesktop = device === 'desktop';
  const shouldShowProgressGraphic = isProfileAudioEnabled && deviceIsDesktop;
  const eligibilityAndProfileQuestions = (
    <>
      {displayEnrollment && (
        <EnrollmentSection
          survey={survey}
          handleSaveEnrollmentAnswer={handleSaveEnrollmentAnswer}
          handleSectionChange={(isValid, isComplete) =>
            handleSectionChange({ key: 'enrollment', isValid, isComplete })
          }
          isDirty={getIsDirty('enrollment')}
          isDuringOE={isDuringOE}
          isProfileAudioEnabled={isProfileAudioEnabled}
          handleSetEnrollmentEventType={(value) =>
            handleSetEnrollmentEventType(value, enrollmentEvent?.jv_publication_uuid, employeeId)
          }
          setHiddenAlert={setHiddenAlert}
          hasActivePublication={!!activePublication}
          hasUpcomingPublication={!!upcomingPublication}
        />
      )}
      {displayEligibility && (
        <EligibilitySection
          questions={questions}
          responses={responses}
          isDirty={getIsDirty('eligibility')}
          selectedPublicationKey={selectedPublicationKey}
          hiddenQuestionIds={hiddenQuestions.eligibility}
          submitClientSurveyResponses={submitClientSurveyResponses}
          handleSectionChange={(isValid, isComplete) => {
            handleSectionChange({
              key: 'eligibility',
              isValid,
              isComplete,
            });
          }}
        />
      )}

      {displayLocation && (
        <LocationSection
          zipcode={zipcode}
          stateCode={stateCode}
          stateCodeOptions={stateCodeOptions}
          countyResults={countyResults}
          surveyQuestions={questions}
          surveyResponses={responses}
          collectTaxInputs={collectTaxInputs}
          hiddenQuestionIds={hiddenQuestions.location}
          selectedPublicationKey={selectedPublicationKey}
          searchCounties={searchCounties}
          resetCounties={resetCounties}
          handleZipChange={handleZipChange}
          handleStateChange={handleStateChange}
          submitClientSurveyResponses={submitClientSurveyResponses}
          handleSectionChange={(isValid, isComplete) =>
            handleSectionChange({ key: 'location', isValid, isComplete })
          }
          isDirty={getIsDirty('location')}
          isProfileAudioEnabled={isProfileAudioEnabled}
          setHiddenAlert={setHiddenAlert}
        />
      )}
      {displayMember && (
        <MemberSection
          policyholder={policyholder}
          spouse={spouse}
          dependents={dependents}
          memberConfig={memberConfig}
          drugResults={drugResults}
          isRxLoading={isRxLoading}
          isRxSkipped={isRxSkipped}
          planYear={planYear}
          handleEditPolicyholder={handleEditPolicyholder}
          handleEditSpouse={handleEditSpouse}
          handleEditDependent={handleEditDependent}
          handleDeleteSpouse={handleDeleteSpouse}
          handleDeleteDependent={handleDeleteDependent}
          handleSectionChange={(isValid, isComplete) =>
            handleSectionChange({ key: 'member', isValid, isComplete })
          }
          isDirty={getIsDirty('member')}
          isProfileAudioEnabled={isProfileAudioEnabled}
          setHiddenAlert={setHiddenAlert}
          queryDrugs={queryDrugs}
        />
      )}
      {displayPregnancy && (
        <PregnancySection
          survey={survey}
          handleSavePregnancyAnswer={handleSavePregnancyAnswer}
          handleSectionChange={(isValid, isComplete) =>
            handleSectionChange({ key: 'pregnancy', isValid, isComplete })
          }
          isNextDirty={getIsDirty('income')}
          isProfileAudioEnabled={isProfileAudioEnabled}
          setHiddenAlert={setHiddenAlert}
        />
      )}
      {displayIncome && (
        <IncomeSection
          policyholder={policyholder}
          spouse={spouse}
          filingStatus={filingStatus as FilingStatus}
          collectTaxInputs={collectTaxInputs}
          handleSaveFilingStatus={handleSaveFilingStatus}
          handleEditPolicyholder={handleEditPolicyholder}
          handleEditSpouse={handleEditSpouse}
          handleSectionChange={(isValid, isComplete) =>
            handleSectionChange({ key: 'income', isValid, isComplete })
          }
          isDirty={getIsDirty('income')}
          isProfileAudioEnabled={isProfileAudioEnabled}
          setHiddenAlert={setHiddenAlert}
          isSkipIncomeEnabled={isSkipIncomeEnabled}
        />
      )}
      {displayRisk && (
        <RiskAssessmentSection
          survey={survey}
          handleChangeRiskQuestion1={handleChangeRiskQuestion1}
          handleChangeRiskQuestion2={handleChangeRiskQuestion2}
          handleSectionChange={(isValid, isComplete) =>
            handleSectionChange({ key: 'riskAssessment', isValid, isComplete })
          }
          isDirty={getIsDirty('riskAssessment')}
          isProfileAudioEnabled={isProfileAudioEnabled}
          setHiddenAlert={setHiddenAlert}
        />
      )}
      {displayCapacity && (
        <CapacityToPaySection
          survey={survey}
          handleChangeCapacityToPay={handleChangeCapacityToPay}
          handleSectionChange={(isValid, isComplete) =>
            handleSectionChange({ key: 'capacityToPay', isValid, isComplete })
          }
          isDirty={getIsDirty('capacityToPay')}
          isProfileAudioEnabled={isProfileAudioEnabled}
          setHiddenAlert={setHiddenAlert}
        />
      )}
      {displayIncentives && (
        <IncentiveSection
          isLoading={isIncentiveLoading}
          incentiveSurvey={incentiveSurvey}
          incentiveSurveyAnswers={incentiveSurveyAnswers}
          isDirty={getIsDirty('incentives')}
          submitIncentiveSurveyResponses={submitIncentiveSurveyResponses}
          handleSectionChange={(isValid, isComplete) =>
            handleSectionChange({ key: 'incentives', isValid, isComplete })
          }
          hasSpouse={spouse.isComplete}
        />
      )}
      {displayContinue && <ContinueBanner />}
    </>
  );

  const shouldAnimate = !isDevMode && isFirstPageView;

  const { audioState, setAudioState, playAudio } = useAudioContext();

  return (
    <>
      {isProfileAudioEnabled && (
        <Box pos="sticky" top={0} zIndex={999} borderBottom="var(--border-thick)" bg="white" py="2">
          {/* todo PageLayoutContainer is pretty heavy and can maybe be simplified. */}
          <PageLayoutContainer size="normal" bg="none">
            <div className="inner-wrapper" data-testid="render-media-simpler">
              <RenderMediaControls
                playPauseButtonIcon="Play" // todo make this smarter, probably want to push this further down.
                isSkipEnabled={false}
                isPlayEnabled={false}
              />
            </div>
          </PageLayoutContainer>
        </Box>
      )}
      <AriaHiddenAlert>{hiddenAlert}</AriaHiddenAlert>
      <FadeUp
        isOpen
        translateY={shouldAnimate ? '-240px' : '0px'}
        delay={shouldAnimate ? 1500 : 0}
        timeout={shouldAnimate ? 400 : 0}
      >
        <ContentfulHeaderWrapper
          sectionKey="profile_section"
          useIntegratedCustomerSubheaderText={is_integration_pre_fill_enabled && isIntegratedUser}
          isReturnUser={!!isReturnUser}
          animate={shouldAnimate}
        />
      </FadeUp>
      <ContentfulInfoBars sectionKey="profile_section" />
      <AriaHidden>{hiddenInstructions}</AriaHidden>
      <PageLayout page="profile">
        <ProfileContainer isFirstPageView={shouldAnimate}>
          {!deviceIsDesktop && <PrivacyBanner isFirstPageView={shouldAnimate} />}
          {readyToRenderEligibilitySection ? (
            eligibilityAndProfileQuestions
          ) : (
            <LoadingCard data-testid="spinner">
              <Flex justify="center" p={16}>
                <Spinner color="--input-gray" size="large" />
              </Flex>
            </LoadingCard>
          )}
        </ProfileContainer>
        {deviceIsDesktop && (
          <RightColumnContainer isFirstPageView={shouldAnimate}>
            {shouldShowProgressGraphic ? <ProgressGraphic /> : <PrivacyBanner isFirstPageView={false} />}
          </RightColumnContainer>
        )}
        {isProfileAudioEnabled && (
          <ProfileAudioContainer>
            <ProfileAudioSubsContainer>
              <Audio
                title="alex-audio"
                volume={1}
                playing={audioState.isPlaying}
                muted={audioState.isMuted}
                url={audioState.urlToAudio}
                closedCaptions={audioState.areClosedCaptionsEnabled}
                closedCaptionVtt={audioState.urlToClosedCaptionVtt}
                className="profile-audio"
                allowCrossOrigin
                onEnded={() => {
                  // Clear block on section complete audio
                  // if it was set by prefill
                  if (skipSectionCompleteAudio) {
                    setSkipSectionCompleteAudio(false);
                  }
                  /*
                  This solves a couple problems:

                  1. Allows us to replay audio tooltips.
                  2. Enables Firefox to show profile audio subs more than once.
                  however, in firefox, overlapped audio files will not show CC.
                   */
                  setAudioState({
                    ...audioState,
                    currentRef: undefined,
                    isPlaying: false,
                    urlToClosedCaptionVtt: '',
                    urlToAudio: '',
                  });
                }}
              />
            </ProfileAudioSubsContainer>
          </ProfileAudioContainer>
        )}
      </PageLayout>
    </>
  );
};

export function mapDispatchToProps(dispatch) {
  return {
    fetchHousehold: (memberConfig) => dispatch(getHousehold(memberConfig)),
    searchCounties: (zipcode) => dispatch(searchLocations(zipcode)),
    resetCounties: () => dispatch(resetCountyResults()),
    submitClientSurveyResponses: (responses: ClientSurveyResponsesByYear) =>
      dispatch(submitClientSurvey(responses)),
    submitIncentiveSurveyResponses: (responses: Record<string, IncentiveSurveyAnswer | null>) =>
      dispatch(submitIncentiveSurvey(responses)),
    handleEditPolicyholder: (fields, memberConfig) => dispatch(editPolicyholder(fields, memberConfig)),
    handleEditSpouse: (fields, memberConfig) => dispatch(editSpouse(fields, memberConfig)),
    handleEditDependent: (memberId, fields, memberConfig) =>
      dispatch(editDependent(memberId, fields, memberConfig)),
    handleDeleteSpouse: () => dispatch(removeSpouse()),
    handleDeleteDependent: (memberId) => dispatch(removeDependent(memberId)),
    handleZipChange: (value) => dispatch(changeFormValue(value, 'zipcode')),
    handleStateChange: (value) => dispatch(changeFormValue(value, 'state_code')),
    handleSavePregnancyAnswer: (value) => dispatch(changeSurveyValue(value, 'plan_child_question')),
    handleSaveFilingStatus: (value) => dispatch(changeFormValue(value, 'filing_status')),
    queryDrugs: (query) => dispatch(searchDrugs(query)),
    handleChangeRiskQuestion1: (value) => dispatch(changeSurveyValue(value, 'risk_question_1')),
    handleChangeRiskQuestion2: (value) => dispatch(changeSurveyValue(value, 'risk_question_2')),
    handleChangeCapacityToPay: (value) => dispatch(changeSurveyValue(value, 'capacity_to_pay')),
    handleSaveEnrollmentAnswer: (value) => dispatch(changeSurveyValue(value, 'enrollment_question')),
    prefillUserProfile: (memberConfig) => dispatch(prefillUserProfile(memberConfig)),
    handleSetEnrollmentEventType: (
      value: EnrollmentEventType | null,
      publicationId?: string,
      employeeId?: string,
    ) => dispatch(setEnrollmentEventType(value, publicationId, employeeId)),
    deselectPlan: () => dispatch(selectHealthPlan({})),
    createUpdateHouseholdAndIncentiveSurvey: () => dispatch(createOrUpdateHousehold()),
  };
}

const mapStateToProps = createStructuredSelector<GlobalReducerState, ReduxProps>({
  isHouseholdLoaded: makeSelectIsHouseholdLoaded(),
  questions: makeGetSurveyQuestions(),
  responses: makeSelectClientSurveyResponses(),
  clientSurveyIsLoaded: makeSelectClientSurveyIsLoaded(),
  prefillHasCompleted: makeSelectProfileField('prefillHasCompleted'),
  policyholder: makeSelectPolicyholder(),
  spouse: makeSelectSpouse(),
  dependents: makeSelectDependents(),
  zipcode: makeSelectProfileField('zipcode'),
  stateCode: makeSelectProfileField('state_code'),
  stateCodeOptions: makeGetStateCodeOptions(),
  countyResults: makeSelectCountyResults(),
  enrollmentEvent: makeGetSelectedEnrollmentEvent(),
  collectTobaccoUsageForPolicyholder: makeSelectShouldShowTobaccoQuestion('policyholder'),
  collectTobaccoUsageForSpouse: makeSelectShouldShowTobaccoQuestion('spouse'),
  collectTaxInputs: makeSelectConfigField('collect_tax_inputs'),
  collectIsSpouseOrDomesticPartner: makeSelectConfigField('show_domestic_partner_question'),
  filingStatus: makeSelectProfileField('filing_status'),
  drugResults: makeSelectDrugResults(),
  isRxLoading: makeSelectRxIsLoading(),
  isRxSkipped: makeSelectRxIsSkipped(),
  survey: makeSelectSurvey(),
  showClientSurvey: makeSelectShowClientSurvey(),
  isFirstPageView: selectIsFirstPageView(PROFILE_PATH),
  householdId: makeGetHouseholdId(),
  selectedProduct: makeGetSelectedProduct(),
  hiddenQuestions: makeSelectProfileField('hiddenQuestions'),
  user: makeSelectGlobalField('user'),
  selectedPublicationKey: makeGetSelectedPublicationKey(),
  isIntegratedUser: makeSelectConfigField('is_integrated'),
  prefillIntegratedProfile: makeSelectConfigField('prefill_integrated_profile'),
  isIncentiveLoading: makeSelectProfileField('incentiveSurveyIsLoading'),
  incentiveSurvey: makeSelectProfileField('incentiveSurvey'),
  incentiveSurveyAnswers: makeSelectProfileField('incentiveSurveyAnswers'),
  employeeId: makeSelectJvpField('employeeId'),
  activePublication: makeSelectJvpField('active'),
  upcomingPublication: makeSelectJvpField('upcoming'),
  isDuringOE: makeGetIsDuringOE(),
  isReturnUser: makeSelectJvpField('isReturnUser'),
  planYear: makeGetSelectedPublicationPlanYear(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({
  key: 'profilePage',
  saga,
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default compose(withSaga, withConnect)(ProfilePage);
