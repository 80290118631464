import { useAuth0 } from '@auth0/auth0-react';
import { useDisclosure } from '@chakra-ui/react';
import fromEntries from '@ungap/from-entries';
import URLSearchParams from '@ungap/url-search-params';
import { push } from 'connected-react-router';
import IdleTracker from 'idle-tracker';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { GlobalReducerState } from 'app/reducers';
import CtaBar from 'Containers/App/CtaBar';
import { Navigation } from 'Containers/App/Navigation';
import { AlexIdUser, AlexProducts, JVPState } from 'Containers/App/types';
import CommercialRoutes from 'Containers/CommercialRoutes';
import { makeGetShowHsaNav } from 'Containers/CommercialRoutes/selectors';
import { makeGetShowTaxSavings } from 'Containers/OverviewPage/selectors';
import {
  makeGetSelectedDataRevisionLocation,
  makeGetSelectedEnrollmentEventId,
  makeGetSelectedProduct,
} from 'Containers/ProfilePage/selectors';
import { ContentfulType } from 'ContentfulDefaults/ContentfulType';
import { AudioProvider } from 'Contexts/audioContext';
import { CompareProvider } from 'Contexts/compareContext';
import { ConfigProvider } from 'Contexts/configContext';
import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { IntroProvider } from 'Contexts/introContext';
import PremiumProvider from 'Contexts/premiumContext';
import ProfileProvider, { ProgressMap } from 'Contexts/profileContext';
import { ProviderSelectionProvider } from 'Contexts/providerSelectionContext';
import { TextProvider } from 'Contexts/textContext';
import { ViewportProvider } from 'DesignLibrary/context/viewportContext';
import { DesignSystemVariables } from 'DesignLibrary/global';
import { DefaultError } from 'Shared/Error500';
import { ErrorContainer } from 'Shared/Error500/styled';
import { Recommendation } from 'Types/entities';
import { configureAnalytics } from 'Utils/analytics';
import { ClientConfig } from 'Utils/apiTypes';
import history from 'Utils/history';
import injectSaga from 'Utils/injectSaga';
import {
  createInsightsSession,
  createUnifiedSessionId,
  sendInsightsEvent,
  updateInsightsDefaults,
} from 'Utils/insights';
import Logger from 'Utils/logger';
import { ERROR_PATH, VIEW_BENEFIT_PATH, VIEW_PLAN_PATH, WELCOME_PATH } from 'Utils/urls';

import {
  changeStep,
  getAAPIUser,
  getJVPBootstrap,
  handleUnlockMainNav,
  loadConfig,
  pulseJVPSession,
  setAlexIdUuid,
  setBCUrlHash,
  setDemoMode,
  setJVPSessionId,
  setPageViewed,
  setUnvalidatedSegmentSlug,
} from './actions';
import {
  DEFAULT_IDLE_TIME_LENGTH,
  GO_ENROLLMENT_EVENT_ID,
  IDLE_TIME_LENGTH_FOR_STAGING,
  JVP_SESSION_PULSE_TIME,
} from './constants';
import saga from './saga';
import conduentClientScript from './scripts/conduent';
import {
  makeGetJvpHasPublicationWithFlag,
  makeSelectCommercialField,
  makeSelectConfigField,
  makeSelectGlobalField,
  makeGetCanChangePayPeriod,
} from './selectors';
import { AppWrapper } from './styled';
import {
  defaultCookieDomain,
  deleteCookie,
  formatPathForInsights,
  getCookie,
  getEmployerSlugFromLocation,
  getSegmentSlugFromLocation,
  setCookie,
} from './utils';
import ProviderSelectionModal from '../ResultPage/ProviderSelectionModal';

import 'DesignLibrary/styles.css';

const LANDING_ROUTES = [WELCOME_PATH];

export interface AppParentProps {
  initialTextMap: ContentfulType | Record<string, never>;
  initialProfileMap: ProgressMap | Record<string, never>;
  getStore: () => GlobalReducerState;
}

export interface AppStateProps {
  config: ClientConfig;
  isConfigLoaded: boolean;
  isFullBenefitEnabled: boolean;
  showLanguageToggle: boolean;
  canChangePayPeriod: boolean;
  unlockedMainPath: string;
  showHsaNav: boolean;
  showTaxSavings: boolean;
  isUnderConstruction: boolean;
  isIntegrated: boolean;
  jvp: JVPState;
  isAlexIdEnabled: boolean;
  isConduentScriptEnabled: boolean;
  recommendations: Recommendation[];
  enrollmentEventId?: string;
  jvpDataRevisionLocation?: string;
  builderCustomerKey: string | null;
  user?: AlexIdUser;
  isFetchingAAPIUser?: boolean;
  unvalidatedSegmentSlug: string;
  error: Error | null;
  invalidateUrlsWithoutSegmentSlug: boolean;
  hasUnsavedProfileChanges: boolean;
  selectedProduct: AlexProducts | '';
  validatedSegmentSlug: string | null;
  segmentSlugProcessed: boolean;
}

export interface AppDispatchProps {
  loadConfig: () => void;
  next: (path: string) => void;
  setPath: (path: string) => void;
  handleUnlockMainPath: () => void;
  updatePageViewed: (path: string, viewed: boolean) => void;
  getJVPBootstrap: (userId: string, employerSlug: string) => void;
  dispatchSetJVPSessionId: (sessionId: string) => void;
  dispatchPulseJVPSession: (sessionId?: string) => void;
  dispatchSetDemoMode: (isDemoMode?: boolean) => void;
  dispatchGetAAPIUserBySessionId: (sessionId?: string) => void;
  dispatchSetUnvalidatedSegmentSlug: (slug: string) => void;
  dispatchSetBCUrlHash: (hash: string) => void;
  dispatchSetAlexIdUuid: (uuid: string) => void;
}

export type AppProps = AppParentProps & AppStateProps & AppDispatchProps;

export const App = ({
  config,
  isConfigLoaded,
  isFullBenefitEnabled,
  showLanguageToggle,
  canChangePayPeriod,
  unlockedMainPath,
  showHsaNav,
  showTaxSavings,
  isUnderConstruction,
  isIntegrated,
  jvp,
  isAlexIdEnabled,
  isConduentScriptEnabled,
  recommendations,
  enrollmentEventId,
  jvpDataRevisionLocation,
  builderCustomerKey,
  user,
  isFetchingAAPIUser,
  unvalidatedSegmentSlug,
  validatedSegmentSlug,
  segmentSlugProcessed,
  initialTextMap,
  initialProfileMap,
  error,
  invalidateUrlsWithoutSegmentSlug,
  hasUnsavedProfileChanges,
  selectedProduct,
  getStore,
  setPath,
  loadConfig,
  next,
  updatePageViewed,
  handleUnlockMainPath,
  getJVPBootstrap,
  dispatchSetJVPSessionId,
  dispatchPulseJVPSession,
  dispatchSetDemoMode,
  dispatchGetAAPIUserBySessionId,
  dispatchSetUnvalidatedSegmentSlug,
  dispatchSetBCUrlHash,
  dispatchSetAlexIdUuid,
}: AppProps) => {
  const prevConfig = useRef(config);
  const [navHistory, setNavHistory] = useState<string[]>([]);
  const [isDevMode, setIsDevMode] = useState<boolean | null>(null);
  const [isPulsing, setIsPulsing] = useState<boolean | null>(null);
  const [ip, setIP] = useState('');
  const [retryCount, setRetryCount] = useState(0);
  const { isOpen, onClose, onOpen } = useDisclosure();
  /*
  For non-integrated, signed-in users we rely on this useAuth0 helper for being responsible for handing us an auth0_uuid
  which also acts as our ALEX ID.

  For integrated users, this block is not sufficient and instead rely on AAPI for translating a session_id to an
  auth0_uuid.

  See https://gitlab.com/jellyvision/code/platform/alex_api/-/merge_requests/1401
  */
  const { isAuthenticated, isLoading: isAuth0Loading, user: auth0User } = useAuth0();

  const timeoutLength =
    window.location.hostname.includes('localhost') || window.location.hostname.includes('start-testing')
      ? IDLE_TIME_LENGTH_FOR_STAGING
      : DEFAULT_IDLE_TIME_LENGTH;
  const idleTracker = new IdleTracker({
    timeout: timeoutLength,
    onIdleCallback: () => createUnifiedSessionId(true),
  });
  idleTracker.start();

  const urlSearchParams = new URLSearchParams(window.location.search);

  const devUrlParam = urlSearchParams.get('dev');
  const demoUrlParam = urlSearchParams.get('demo');

  useEffect(() => {
    if (Array.from(urlSearchParams.keys()).length) {
      updateInsightsDefaults({ url_parameters: urlSearchParams.toString() });
    }
  }, [urlSearchParams.toString()]);

  const logoutRedirect = localStorage.getItem('logoutRedirect');
  if (logoutRedirect) {
    localStorage.removeItem('logoutRedirect');
    window.location.replace(logoutRedirect);
  }

  const getIP = useCallback(async () => {
    const res = await fetch('https://api.ipify.org?format=json', { method: 'GET' });

    if (res.ok) {
      const { ip } = await res.json();
      setIP(ip);
    }
  }, []);

  useEffect(() => {
    if (retryCount < 3 && !ip) {
      getIP().catch((e) => {
        Logger.error(e);
        setRetryCount(retryCount + 1);
        return null;
      });
    }
    updateInsightsDefaults({ ip_address: ip });
  }, [ip, retryCount]);

  useEffect(() => {
    // This useEffect needs to run before the useEffect that handles redirects
    const slug = getSegmentSlugFromLocation(window.location);
    const hash = window.location.hash || '';
    if (slug) {
      dispatchSetUnvalidatedSegmentSlug(slug);
    } else if (invalidateUrlsWithoutSegmentSlug && !unvalidatedSegmentSlug) {
      next(ERROR_PATH);
    }

    if (hash) {
      dispatchSetBCUrlHash(hash);
    }
  }, [isConfigLoaded]);

  useEffect(() => {
    // todo why is configureAnalytics called on state changes to isAuth0Loading?
    configureAnalytics();

    // TODO - refactor for readability; this isn't great
    const path = window.location.pathname;

    if (!config.client && !isAuth0Loading) {
      // redirect to landing if trying to access !landingPage without logging in
      if (!LANDING_ROUTES.includes(path)) {
        next(WELCOME_PATH);
      }
      loadConfig();
    }
    // called after login and redirect
    else if (isAuthenticated && path !== WELCOME_PATH) {
      localStorage.removeItem('store');
      localStorage.removeItem('contentful');
      localStorage.removeItem('profileMap');
    } else if (!localStorage.getItem('store') && isAuthenticated) {
      loadConfig();
    }

    handleLocalDevMode();

    if (demoUrlParam && demoUrlParam === 'true') {
      dispatchSetDemoMode(true);
    }

    const jvpSessionId = getCookie('session_id');
    if (jvpSessionId) {
      dispatchSetJVPSessionId(jvpSessionId);

      deleteCookie('session_id', defaultCookieDomain(), '/');
    }

    return () => {
      history.listen((location: Location) => {
        setPath(location.pathname);
      });
    };
  }, [isAuth0Loading]);

  /**
   * This section is what enables us to go from a session-id cookie to
   * a jvp-known auth0_uuid, thanks to an endpoint in AAPI.
   *
   * Side effect produced here is setting user.alex_id_uuid.
   */
  const shouldFetchAAPIUser = isIntegrated && jvp.sessionId;

  useEffect(() => {
    if (isIntegrated && !jvp.sessionId) {
      // Integrated customers should not be able to access Go
      // without first logging in via their BenAdmin platform
      next(ERROR_PATH);
    }

    // See explanation on `shouldFetchAAPIUser` for context.
    if (shouldFetchAAPIUser) {
      // Local EITS Users: Force true above and hard-code a session ID from EITS here.
      dispatchGetAAPIUserBySessionId(jvp.sessionId);
    }
    return undefined;
  }, [isIntegrated, jvp.sessionId]);

  // eslint-disable-next-line consistent-return
  const handleLocalDevMode = () => {
    const devLocalStorage = localStorage.getItem('dev');

    // user has 'dev' value cached in localstorage
    if (devLocalStorage) {
      // user explicitly set 'dev=false'; remove from localstorage, fire alert on page refresh
      if (devUrlParam === 'false') {
        localStorage.removeItem('dev');

        // warning dialogue when user tries to refresh page
        window.onbeforeunload = () => '';

        return () => {
          // cleanup alert override on unmount
          window.onbeforeunload = null;

          history.listen((location) => {
            setPath(location.pathname);
          });
        };
      }
      setIsDevMode(true);
    }
    // user has set 'dev=true' in url but it hasn't yet been stored in localstorage
    else if (devUrlParam && devUrlParam === 'true') {
      localStorage.setItem('dev', 'true');
      setIsDevMode(true);
    }
    // dev is not set in localstorage and doesn't exist in the url; no page refresh alert
    else {
      window.onbeforeunload = () => {
        sendInsightsEvent(null, 'try_leave_or_refresh', {
          page: history.location.pathname,
        });
        return '';
      };
    }
  };

  const createJvpEnrollment = () => {
    if (!enrollmentEventId) {
      const employerSlug = getEmployerSlugFromLocation(window.location);
      getJVPBootstrap(user?.alex_id_uuid as string, employerSlug);
    }

    // Will trigger once jvp state gets updated and the jvp state is populated.
    if (enrollmentEventId && jvp.userId) {
      setCookie(GO_ENROLLMENT_EVENT_ID, enrollmentEventId, defaultCookieDomain(), '/');
    }
  };

  /**
   * This effect ensures the ALEX ID aka auth0_uuid is set.
   * This is applicable to logged-in users, EXCLUDING integrated users.
   * Integrated user IDs can only be located via AAPI by converting our
   * session-id cookie to an auth0_uuid.
   */
  useEffect(() => {
    if (isAuthenticated && auth0User) {
      const uuid = auth0User['https://login.myalex.com/uuid'];
      dispatchSetAlexIdUuid(uuid);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // Delay attempting to create enrollment event until
    // we've determined if there is an AAPI user or not
    if (
      !isUnderConstruction &&
      builderCustomerKey &&
      (!shouldFetchAAPIUser || isFetchingAAPIUser === false)
    ) {
      createJvpEnrollment();
    }
  }, [builderCustomerKey, enrollmentEventId, isFetchingAAPIUser]);

  useEffect(() => {
    // format path for readability in insights, if landing: '/' -> 'landing', otherwise
    // just slice out the leading slash (/overview -> overview)
    // Insights events for landing page are handled in a callback from createInsightsSession
    // to avoid sending events without session ids. All other events are handled here.
    if (![WELCOME_PATH].includes(history.location.pathname)) {
      const currentPageId = formatPathForInsights(history.location.pathname, WELCOME_PATH);

      // confirm insights id is present before sending any insights events
      // check for config success - when config fails we get an error in Sentry from formatPathForInsights instead of the config failure
      if (localStorage.getItem('insights_session_id') && config.client && (ip || retryCount === 3)) {
        const lastPageId = formatPathForInsights(navHistory[navHistory.length - 1], WELCOME_PATH);

        // If these events are called, user has just navigated to a new page, send to insights
        // the page they were previously on (page_view_ended), and the page they just loaded (page_view_started)
        sendInsightsEvent(null, 'page_view_ended', { page_id: lastPageId });
        sendInsightsEvent(null, 'page_view_started', {
          page_id: currentPageId,
        });
      }

      // an array of pages the user visits; updates on page change
      setNavHistory([...navHistory, history.location.pathname]);
      updatePageViewed(navHistory[navHistory.length - 1], true);
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (config.client && config.client !== prevConfig.current.client && segmentSlugProcessed) {
      prevConfig.current.client = config.client;
      createInsightsSession(config.client, config.builder_customer_key, validatedSegmentSlug).then(() => {
        // Insights needs to be configured prior to sending events, wait for session creation
        // to fire off first event.

        // Establish defaults before firing first event.
        updateInsightsDefaults({ user_agent: window.navigator.userAgent });

        sendInsightsEvent(null, 'page_view_started', {
          page_id: isUnderConstruction ? 'coming_soon' : 'landing',
        });

        // add landing page route to navhistory
        setNavHistory([...navHistory, history.location.pathname]);
        updatePageViewed(history.location.pathname, true);
      });

      const urlParams = new URLSearchParams(window.location.search);
      const params = fromEntries(urlParams);

      if (Object.keys(params).length > 0) {
        localStorage.setItem('query_string', JSON.stringify(params));
      }
    }
  }, [config, segmentSlugProcessed]);

  useEffect(() => {
    if (user?.alex_id_uuid) {
      updateInsightsDefaults({
        auth0_uuid: user.alex_id_uuid,
      });
    }
  }, [user]);

  useEffect(() => {
    if (jvpDataRevisionLocation) {
      updateInsightsDefaults({ builder_location: jvpDataRevisionLocation });
    }
    let interval: NodeJS.Timeout;
    if (jvp.sessionId && !isPulsing) {
      interval = setInterval(() => {
        dispatchPulseJVPSession(jvp.sessionId);
      }, JVP_SESSION_PULSE_TIME);
      setIsPulsing(true);
    } else {
      return () => clearInterval(interval);
    }
    return undefined;
  }, [jvp]);

  const enableConduentScriptAndReturnScriptElement = () => {
    const head = document.querySelector('head');
    const script = document.createElement('script');

    script.innerHTML = conduentClientScript;
    head?.appendChild(script);

    return script;
  };

  useEffect(() => {
    let conduentScriptElement: HTMLScriptElement;

    if (isConduentScriptEnabled) {
      conduentScriptElement = enableConduentScriptAndReturnScriptElement();
    }

    return () => {
      if (conduentScriptElement) {
        document.querySelector('head')?.removeChild(conduentScriptElement);
      }
    };
  }, [isConduentScriptEnabled]);

  // todo reintroduce once we determine cause of state side-effects.
  // useEffect(() => {
  //   let interval: NodeJS.Timeout;
  //
  //   if (isConfigLoaded) {
  //     interval = setInterval(() => {
  //       loadConfig()
  //     }, CONFIG_POLLING_INTERVAL_MS)
  //   } else {
  //     return () => clearInterval(interval)
  //   }
  //   return undefined;
  // }, [isConfigLoaded]);

  // necessary for handling unlocking pages with dynamic path (plan details, benefit details)
  const getFormattedPath = (): string => {
    if (history.location.pathname.includes(VIEW_BENEFIT_PATH)) {
      return `${VIEW_BENEFIT_PATH}/:insuranceType`;
    }
    if (history.location.pathname.includes(VIEW_PLAN_PATH)) {
      return `${VIEW_PLAN_PATH}/:planId`;
    }
    return history.location.pathname;
  };

  const formattedPath = getFormattedPath();

  const isBuilderClientWithNoEligiblePlans = Boolean(
    jvp.userId && ['/overview', '/review'].includes(unlockedMainPath) && recommendations.length === 0,
  );
  // handle config errors
  if (error) {
    return (
      <ErrorContainer>
        <DefaultError errorRoute={() => window.location.reload()} />
      </ErrorContainer>
    );
  }

  const { is_integration_pre_fill_enabled } = useFeatureFlagContext();

  const isBcPath: boolean = selectedProduct === 'bc';

  return (
    <AppWrapper id="app-wrapper" isDevMode={isDevMode || false}>
      {isConfigLoaded && (
        <TextProvider initialMap={initialTextMap}>
          <ConfigProvider config={config}>
            <ViewportProvider>
              <CompareProvider>
                <PremiumProvider defaultPayPeriod={config.default_pay_period}>
                  <ProfileProvider
                    progressMap={initialProfileMap}
                    isIntegrationPreFillEnabled={is_integration_pre_fill_enabled}
                  >
                    <AudioProvider>
                      <ProviderSelectionProvider onOpenProviderModal={onOpen}>
                        <IntroProvider handleNext={next}>
                          <>
                            <DesignSystemVariables />
                            <Navigation
                              canChangePayPeriod={canChangePayPeriod}
                              path={formattedPath}
                              unlockedPath={unlockedMainPath}
                              isFullBenefitEnabled={isFullBenefitEnabled}
                              showLanguageToggle={showLanguageToggle}
                              showHsaNav={showHsaNav}
                              showResultPage={!isBuilderClientWithNoEligiblePlans}
                              showTaxSavings={showTaxSavings}
                              routerPush={history.push}
                              isAlexIdEnabled={isAlexIdEnabled}
                              hasUnsavedProfileChanges={hasUnsavedProfileChanges}
                              handleUnlockPath={handleUnlockMainPath}
                              getStore={getStore}
                              hideNavItems={isBcPath}
                            />
                            <CommercialRoutes isUnderConstruction={isUnderConstruction} />
                            <CtaBar
                              path={history.location.pathname}
                              isBuilderClientWithNoEligiblePlans={isBuilderClientWithNoEligiblePlans}
                              hasUnsavedProfileChanges={hasUnsavedProfileChanges}
                            />
                            <ProviderSelectionModal isOpen={isOpen} onClose={onClose} />
                          </>
                        </IntroProvider>
                      </ProviderSelectionProvider>
                    </AudioProvider>
                  </ProfileProvider>
                </PremiumProvider>
              </CompareProvider>
            </ViewportProvider>
          </ConfigProvider>
        </TextProvider>
      )}
    </AppWrapper>
  );
};

export function mapDispatchToProps(dispatch) {
  return {
    setPath: (path) => dispatch(changeStep(path)),
    loadConfig: () => dispatch(loadConfig()),
    next: (path) => dispatch(push(path)),
    updatePageViewed: (path, viewed) => dispatch(setPageViewed(path, viewed)),
    handleUnlockMainPath: (path) => dispatch(handleUnlockMainNav(path)),
    // JVP
    getJVPBootstrap: (userId, employerSlug) => dispatch(getJVPBootstrap(userId, employerSlug)),
    dispatchSetJVPSessionId: (sessionId) => dispatch(setJVPSessionId(sessionId)),
    dispatchPulseJVPSession: (sessionId) => dispatch(pulseJVPSession(sessionId)),
    dispatchSetDemoMode: (isDemoMode) => dispatch(setDemoMode(isDemoMode)),
    dispatchGetAAPIUserBySessionId: (sessionId) => dispatch(getAAPIUser(sessionId)),
    dispatchSetUnvalidatedSegmentSlug: (slug: string) => dispatch(setUnvalidatedSegmentSlug(slug)),
    dispatchSetBCUrlHash: (hash: string) => dispatch(setBCUrlHash(hash)),
    dispatchSetAlexIdUuid: (uuid: string) => dispatch(setAlexIdUuid(uuid)),
  };
}

const mapStateToProps = createStructuredSelector<GlobalReducerState, AppStateProps>({
  config: makeSelectGlobalField('config'),
  isConfigLoaded: makeSelectGlobalField('isConfigLoaded'),
  isFullBenefitEnabled: makeSelectConfigField('is_fbs_enabled'),
  showLanguageToggle: makeSelectConfigField('show_language_toggle'),
  canChangePayPeriod: makeGetCanChangePayPeriod(),
  unlockedMainPath: makeSelectGlobalField('unlockedMainPath'),
  showHsaNav: makeGetShowHsaNav(),
  showTaxSavings: makeGetShowTaxSavings(),
  isUnderConstruction: makeSelectConfigField('is_under_construction'),
  isIntegrated: makeSelectConfigField('is_integrated'),
  jvp: makeSelectGlobalField('jvp'),
  isAlexIdEnabled: makeGetJvpHasPublicationWithFlag('alex_id_enabled'),
  isConduentScriptEnabled: makeGetJvpHasPublicationWithFlag('conduent_script_enabled'),
  recommendations: makeSelectCommercialField('recommendations'),
  enrollmentEventId: makeGetSelectedEnrollmentEventId(),
  jvpDataRevisionLocation: makeGetSelectedDataRevisionLocation(),
  builderCustomerKey: makeSelectConfigField('builder_customer_key'),
  user: makeSelectGlobalField('user'),
  isFetchingAAPIUser: makeSelectGlobalField('isFetchingAAPIUser'),
  unvalidatedSegmentSlug: makeSelectGlobalField('unvalidatedSegmentSlug'),
  error: makeSelectGlobalField('error'),
  invalidateUrlsWithoutSegmentSlug: makeSelectConfigField('invalidate_urls_without_segment_slug'),
  hasUnsavedProfileChanges: makeSelectGlobalField('hasUnsavedProfileChanges'),
  selectedProduct: makeGetSelectedProduct(),
  validatedSegmentSlug: makeSelectGlobalField('validatedSegmentSlug'),
  segmentSlugProcessed: makeSelectGlobalField('segmentSlugProcessed'),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({
  key: 'globalApp',
  saga,
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default compose(withConnect, withSaga, withRouter)(App);
